export const MAX_HP = 100;
export const HP_THRESHOLD_1 = 50;
export const HP_THRESHOLD_2 = 25;
export const MAX_TOLERANCE = 100;
export const TOLERANCE_THRESHOLD = 40;
export const MAX_HUNGER = 100;
export const HUNGER_THRESHOLD_1 = 50;
export const HUNGER_THRESHOLD_2 = 80;
export const MAX_TIREDNESS = 100;
export const TIREDNESS_THRESHOLD_1 = 50;
export const TIREDNESS_THRESHOLD_2 = 80;
export const MAX_ANGER = 100;
export const ANGER_THRESHOLD_1 = 50;
export const ANGER_THRESHOLD_2 = 80;
export const MAX_LOVE = 100;
export const LOVE_THRESHOLD = 50;
export const PISS_THRESHOLD = 70;